/* eslint-disable */
import React, { useState, useCallback, ReactNode } from 'react'
import { Currency, Pair } from '@pancakeswap-libs/sdk'
import { Heading, Button, ChevronDownIcon, Text, IconButton, Flex, useModal } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import Scanner from 'components/Scanner';
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks'
import TranslatedText from "../TranslatedText"
import { TranslateString } from '../../utils/translateTextHelpers'
import SettingsModal from '../PageHeader/SettingsModal'
import RecentTransactionsModal from '../PageHeader/RecentTransactionsModal'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#FFFFFF')};
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  width:190px;
  :focus,
  :hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.input)};
  }
`

const ShowSelect = styled.button`
  align-items: center;
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${({  theme }) => ( theme.colors.text)};
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  width:190px;
  :focus,
  :hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.input)};
  }
`

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`
const CogIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73 9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.54 5.05 19.27 4.97 19.05 5.05L16.56 6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99996C9.74996 2 9.53996 2.18 9.50996 2.42L9.12996 5.07C8.51996 5.32 7.95996 5.66 7.43996 6.05L4.94996 5.05C4.71996 4.96 4.45996 5.05 4.33996 5.27L2.33996 8.73C2.20996 8.95 2.26996 9.22 2.45996 9.37L4.56996 11.02C4.52996 11.34 4.49996 11.67 4.49996 12C4.49996 12.33 4.52996 12.66 4.56996 12.98L2.45996 14.63C2.26996 14.78 2.21996 15.05 2.33996 15.27L4.33996 18.73C4.45996 18.95 4.72996 19.03 4.94996 18.95L7.43996 17.95C7.95996 18.35 8.51996 18.68 9.12996 18.93L9.50996 21.58C9.53996 21.82 9.74996 22 9.99996 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87 18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.28 19.04 19.54 18.95 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM12 15.5C10.07 15.5 8.49996 13.93 8.49996 12C8.49996 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z"
      fill="currentColor"
    />
  </svg>
)

const HistoryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.28 15.54L17 14.33L13.5 12.25V8H12Z"
      fill="currentColor"
    />
  </svg>
)

const StyledPageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 24px;
      background: #0073c1;
    background: linear-gradient(
90deg,#0073c1,#16181a);
`

const Details = styled.div`
  flex: 1;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  children?: ReactNode
}

const TvChartPanel = React.memo(({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = TranslateString(132, 'Input'),
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  children 
}: CurrencyInputPanelProps) => { 
  const [modalOpen, setModalOpen] = useState(false)
  const [show, setisShow] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const [onPresentSettings] = useModal(<SettingsModal />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal />)

  return (
    <>
    <InputPanel className="swap-inpt-wrap" id={id}>
      <StyledPageHeader>
      <div className="flex flex-row justify-between items-stretch	">
          <div className="flex flex-col md:flex-row justify-center">
        <Details className="h-20">
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
         
         <CurrencySelect
           selected={!!currency}
           className="open-currency-select-button cardheadersearch"
           onClick={() => {
             if (!disableCurrencySelect) {
               setModalOpen(true)
             }
           }}
         >
           <Aligner className="p-4 h-14">
             {pair ? (
               <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
             ) : currency ? (
               <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
             ) : null}
             {pair ? (
               <Text className="p-4">
                 {pair?.token0.symbol}:{pair?.token1.symbol}
               </Text>
             ) : (
                 <Text className="p-4">
                  
                 {(currency && currency.symbol && currency.symbol.length > 20
                   ? `${currency.symbol.slice(0, 4) 
                     }...${ 
                     currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)}`
                   : currency?.symbol) || <TranslatedText translationId={82}>Search</TranslatedText>}
               </Text>
             )}
             {!disableCurrencySelect && <ChevronDownIcon />}
           </Aligner>
              </CurrencySelect>
             
       </InputRow>
            
            
            </Details>
            
            <Details className="h-20">
              <InputRow selected={disableCurrencySelect}>
              <ShowSelect className="open-currency-select-button cardheadersearch" onClick={() => {
               setisShow(!show)
                    }} >
                <Aligner className="p-4 h-14">
                  <Text className="p-4">
                
                 {show ? <TranslatedText translationId={82}>Hide Scanner</TranslatedText> : <TranslatedText translationId={82}>Show Scanner</TranslatedText>} 
                    
                  
                    </Text>
                    <ChevronDownIcon />
                  </Aligner>
                  </ShowSelect>
               </InputRow>
              </Details>
            </div>
            <div className="">
        <IconButton variant="text" onClick={onPresentSettings} title="Settings">
          <CogIcon />
        </IconButton>
        <IconButton variant="text" onClick={onPresentRecentTransactions} title="Recent transactions">
          <HistoryIcon />
            </IconButton>
            </div>
      </div>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
      
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
      {show ? currency ? Object.prototype.hasOwnProperty.call(currency, "address") ? <Scanner currency={currency?.['address']} /> : <Scanner currency={null} /> : <Scanner currency={null} /> : ''}
      
      </>
  )
})

export default TvChartPanel
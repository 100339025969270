import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [

  {
    label: 'Trade',
    icon: 'Swap',
    initialOpenState: true,
    href: '/trade'
  },
  {
    label: 'Liquidity',
    icon: 'Liquidity',
    href: '/pool'
  },
  {
    label: 'Launchpad',
    icon: 'Launchpad',
    href: 'https://ido.degem.tech'
  },
  {
    label: 'Gem Cave',
    icon: 'GemCave',
    href: '/#'
  },
  {
    label: 'Refinery',
    icon: 'Refinery',
    href: '/#'
  },
  {
    label: 'Treasury',
    icon: 'Treasury',
    href: '/#'
  },
  {
    label: 'Audit by Solid Group',
    icon: 'AuditIcon',
    href: 'https://audits.solidgrp.io/degem-audit-report/',
  },

]

export default config

/* eslint-disable */
import React, { Component } from 'react'
import './UserCard.css'
import UserData from './UserData'

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            display_data: false
        }
    }
    getUserData = (event, data) => {
        this.setState({
            display_data: false
        })
        setTimeout(() => {
            this.setState({
                data: data,
                display_data: true
            })
        }, 500);
    }

    render() {
        let user_data = ''
        let maxHoldString
        let isMintScam
        if (this.state.data && this.state.display_data) {
            user_data = <UserData data={this.state.data} />
        }
        if (this.props && this.props.user && this.props.user.holders && this.props.user.holders.maxHolderAddress) {
             maxHoldString = this.props.user.holders ? this.props.user.holders.maxHolderAddress
                ? this.props.user.holders.maxHolderAddress : ''
                : ''
        } else{ maxHoldString = ''}
        if (this.props && this.props.user && this.props.user.code && this.props.user.holders.detectedScams) {
             isMintScam = this.props.user ? this.props.user.code.detectedScams
                .map((scam) => scam.type)
                .includes('mint') : false
        }else{isMintScam = false}

        return (
            <div className="content w-full justify-center items-center shadow-lg pb-4 rounded-lg flex flex-col">
            <div className='content w-full justify-center items-center rounded-lg flex flex-col'>
               
                    
                        
                        <a className="relative bg-blue-500 textColor hover:text-white p-3 rounded text-md font-bold transition duration-300 ease-in-out hover:bg-blue-600 overflow-visible" href={`https://bscscan.com/token/${this.props.name}`} >View on BscScan</a>
                    
                    <div className="block md:flex justify-between md:-mx-2 mt-10 ">
                    <div className="shadow-lg border-indigo-500 rounded-2xl overflow-hidden p-4 hover:shadow-2xl cursor-pointer">
                        <div className="cardHeader rounded-2xl p-3 flex h-20 items-center">
                        <h4 className="textColor text-2xl" >Information</h4> </div>
                            <p className='w-4/5 mx-auto py-3 px-3 text-lg tracking-wide text-center'>Honeypot :{this.props.user && this.props.user.trade ?this.props.user.trade.isHoneypot ?  'yes' : 'no (simulation successfull)' : 'undefined'}</p>
                            <p className='w-4/5 mx-auto py-3 px-3 text-lg tracking-wide text-center'>{this.props.user && this.props.user.trade ? this.props.user.trade.maxSell ? `Max Sell: ${this.props.user.trade.maxSell}` : 'Max Sell: undefined' : ''}</p>
                            <p className='w-4/5 mx-auto py-1 px-3 text-lg tracking-wide text-center'>{this.props.user && this.props.user.trade ? this.props.user.trade.buyFee ? `Buy Fee: ${this.props.user && this.props.user.trade.buyFee}` : 'undefined' : ''}</p>
                            <p className='w-4/5 mx-auto py-1 px-3 text-lg tracking-wide text-center'>{this.props.use && this.props.user.trade ? this.props.user.trade.sellFee ? `Sell Fee: ${this.props.user && this.props.user.trade.sellFee}` : 'undefined' : ''}</p>
                            <p className='w-4/5 mx-auto py-1 px-3 text-lg tracking-wide text-center'>{this.props.user && this.props.user.trade ? this.props.user.trade.buyGas ? `Buy Gas: ${this.props.user && this.props.user.trade.buyGas}` : 'undefined' : ''}</p>
                            <p className='w-4/5 mx-auto py-1 px-3 text-lg tracking-wide text-center'>{this.props.user && this.props.user.trade ? this.props.user.trade.sellGas ? `Sell Gas: ${this.props.user.trade.sellGas}` : 'undefined' : ''}</p>
                            </div>
                   

                    <div className=" shadow-lg border-indigo-500 rounded-2xl overflow-hidden p-4 hover:shadow-2xl cursor-pointer ">
                        
                                <div className="cardHeader rounded-2xl p-3 flex h-20 items-center"><h4 className="textColor text-2xl">Overview</h4> <h1 className="textColor border-2 py-2 px-4 rounded-full text-xl ml-auto" >{this.props.user && this.props.user.totalScore}</h1></div>
                                <h5 className='py-6 px-3 tracking-wide text-center text-xl pb-2'>{this.props.user && this.props.user.totalScore > 99 ? `Honeypot !` : this.props.user && this.props.user.totalScore <= 50 ?  'Risk level : 🔴' :  this.props.user && this.props.user.totalScore <= 75 ? 'Risk level : 🟠': this.props.user && this.props.user.totalScore <= 85 ? 'Risk level : 🟡' : 'Risk level : 🟢'}</h5>
                            <p className='py-6 px-3 text-lg tracking-wide text-center w-4/5 lg:w-full mx-auto'>{this.props.user && this.props.user.totalScore > 99 ? `It looks like our trade simulation determined that this token was a honeypot. Be very careful!` : this.props.user && this.props.user.totalScore <= 50 ?  'Our scanner has detected a significant risk that this token will be rugged or become a delayed honeypot. However, the scanner analysis determines the possibilities of the owners, not their intentions.' : this.props.user && this.props.user.totalScore <= 75 ? 'Our scanner has detected a medium risk that this token will be rugged or become a delayed honeypot. However, the scanner analysis determines the possibilities of the owners, not their intentions.': this.props.user && this.props.user.totalScore <= 85 ? 'Our scanner has detected a low risk that this token will be rugged or become a delayed honeypot. However, the scanner analysis determines the possibilities of the owners, not their intentions.' : 'Our scanner has detected no risk that this token will be rugged or become a delayed honeypot. However, if this token isn\'t listed yet, we are missing a lot of data and this scan can\'t be 100% reliable.'}</p>
                        </div>
                        <div className="shadow-lg border-indigo-500 rounded-2xl overflow-hidden p-4  hover:shadow-2xl ">
                        <div className="cardHeader rounded-2xl p-3 flex h-20 items-center"><h4 className="textColor text-2xl">Rug Pull Safety</h4> <h1 className="textColor border-2 py-2 px-4 rounded-full text-xl ml-auto" >{this.props.user && this.props.user.rugScore}</h1></div>
                            <p className='flex flex-row py-3 px-3 text-lg tracking-wide text-center'><span className="mt-2 px-1 py-0.5">Top Holder :</span> <a className="btn-visit bg-pink-500 text-black rounded-lg font-bold textColor text-center px-1 py-0.5 transition duration-300 ease-in-out hover:bg-pink-600 mr-6" href={`https://bscscan.com/address/${maxHoldString? maxHoldString : ''}`} >Address</a></p>
                            <p className='py-3 px-3 text-lg tracking-wide text-center'>{this.props.user && this.props.user.holders ? this.props.user.holders.liquidity ? `Liquidity: Locked` : 'Liquidity: Unlocked' : ''}</p>
                            <p className='py-3 px-3 text-lg tracking-wide text-center'>{this.props.user && this.props.user.holders ? (this.props.user.holders.suspiciousSupply / this.props.user.holders.liquiditySupply) * 100 > 10 ? 'Holders : suspicious' : 'Holders : clean' : ''}</p>
                            <p className='py-3 px-3 text-lg tracking-wide text-center'>{this.props.user &&  this.props.user.holders ? this.props.user.holders.top5Hold ? `Top 5 Holders : ${this.props.user.holders.top5Hold.toFixed(1)}% held` : '' : ''}</p>
                            <p className='py-3 px-3 text-lg tracking-wide text-center'>{isMintScam ? 'Mint : yes' : 'Mint : not detected'}</p>
                    </div>
                        </div>
                       
                       
                        
                    
                    {/* <div className="user-stats">
                        <ul>
                            <li className='user-following'
                                onClick={(event) => this.getUserData(event, `${this.props.user.url}/following`)} >
                                <b>{this.props.user.following}</b>
                                <p>Following</p>
                            </li>
                            <li className='user-followers'
                                onClick={(event) => this.getUserData(event, `${this.props.user.url}/followers`)} >
                                <b>{this.props.user.followers}</b>
                                <p>Followers</p>
                            </li>
                            <li className='user-repos'
                                onClick={(event) => this.getUserData(event, `${this.props.user.url}/repos`)} >
                                <b>{this.props.user.public_repos}</b>
                                <p>Repos</p>
                            </li>
                        </ul>

                    </div> */}
                   
                        {user_data}
                </div>
                </div>
                
        )
    }
}

export default User
/* eslint-disable */
import { Currency, ETHER, Token } from '@pancakeswap-libs/sdk'
import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import './index.css';

import Poocoin from './api'
import EthereumLogo from '../../assets/images/binance-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from "../pancake/CoinLogo"
import { ethers } from 'ethers'
import pcsRouter from './web3/pcsRouter.js'
import numberWithCommas from './web3/numberWithCommas'

let timer
let timer2

const abi = [
  {
    inputs: [
      { internalType: 'address', name: '_factory', type: 'address' },
      { internalType: 'address', name: '_WETH', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'WETH',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenA', type: 'address' },
      { internalType: 'address', name: 'tokenB', type: 'address' },
      { internalType: 'uint256', name: 'amountADesired', type: 'uint256' },
      { internalType: 'uint256', name: 'amountBDesired', type: 'uint256' },
      { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'addLiquidity',
    outputs: [
      { internalType: 'uint256', name: 'amountA', type: 'uint256' },
      { internalType: 'uint256', name: 'amountB', type: 'uint256' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      {
        internalType: 'uint256',
        name: 'amountTokenDesired',
        type: 'uint256',
      },
      { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'addLiquidityETH',
    outputs: [
      { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'factory',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'reserveIn', type: 'uint256' },
      { internalType: 'uint256', name: 'reserveOut', type: 'uint256' },
    ],
    name: 'getAmountIn',
    outputs: [{ internalType: 'uint256', name: 'amountIn', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'reserveIn', type: 'uint256' },
      { internalType: 'uint256', name: 'reserveOut', type: 'uint256' },
    ],
    name: 'getAmountOut',
    outputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
    ],
    name: 'getAmountsIn',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
    ],
    name: 'getAmountsOut',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountA', type: 'uint256' },
      { internalType: 'uint256', name: 'reserveA', type: 'uint256' },
      { internalType: 'uint256', name: 'reserveB', type: 'uint256' },
    ],
    name: 'quote',
    outputs: [{ internalType: 'uint256', name: 'amountB', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenA', type: 'address' },
      { internalType: 'address', name: 'tokenB', type: 'address' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'removeLiquidity',
    outputs: [
      { internalType: 'uint256', name: 'amountA', type: 'uint256' },
      { internalType: 'uint256', name: 'amountB', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'removeLiquidityETH',
    outputs: [
      { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
    outputs: [
      { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
      { internalType: 'bool', name: 'approveMax', type: 'bool' },
      { internalType: 'uint8', name: 'v', type: 'uint8' },
      { internalType: 'bytes32', name: 'r', type: 'bytes32' },
      { internalType: 'bytes32', name: 's', type: 'bytes32' },
    ],
    name: 'removeLiquidityETHWithPermit',
    outputs: [
      { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
      { internalType: 'bool', name: 'approveMax', type: 'bool' },
      { internalType: 'uint8', name: 'v', type: 'uint8' },
      { internalType: 'bytes32', name: 'r', type: 'bytes32' },
      { internalType: 'bytes32', name: 's', type: 'bytes32' },
    ],
    name: 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens',
    outputs: [
      { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'tokenA', type: 'address' },
      { internalType: 'address', name: 'tokenB', type: 'address' },
      { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
      { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
      { internalType: 'bool', name: 'approveMax', type: 'bool' },
      { internalType: 'uint8', name: 'v', type: 'uint8' },
      { internalType: 'bytes32', name: 'r', type: 'bytes32' },
      { internalType: 'bytes32', name: 's', type: 'bytes32' },
    ],
    name: 'removeLiquidityWithPermit',
    outputs: [
      { internalType: 'uint256', name: 'amountA', type: 'uint256' },
      { internalType: 'uint256', name: 'amountB', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapETHForExactTokens',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapExactETHForTokens',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapExactTokensForETH',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapExactTokensForTokens',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
      { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'amountInMax', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapTokensForExactETH',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
      { internalType: 'uint256', name: 'amountInMax', type: 'uint256' },
      { internalType: 'address[]', name: 'path', type: 'address[]' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'deadline', type: 'uint256' },
    ],
    name: 'swapTokensForExactTokens',
    outputs: [
      { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
]
const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`
const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/")

const apiKey = 'HRUVMFH8E4PM65HFUNWZ2XTUBQP1GRKMRC'

const pcsRouterContract = new ethers.Contract('0x10ED43C718714eb63d5aA57B78B54704E256024E', abi, provider)

async function getAmountsOut(quoteAmount, path) {
  return await pcsRouterContract.functions['getAmountsOut'](
    quoteAmount,
    path,
    { gasLimit: 1000000000000 }
  ).catch(function() {
    // console.log("error");
})
}

const TVChartHeader = React.memo(({
  currency,
  symbol,
  size = '24px',
  style
}: {
    currency?: any
    symbol?: any
  size?: string
  style?: React.CSSProperties
}) => { 
  

 

  const symbolInfo = currency || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
  async function loadBitqueryData() {

   if(price !== '0'){
    
    

    let query = `
    {
       ethereum(network: bsc) {
         address(address: {is: "${symbolInfo}"})
				 {annotation
         address
         smartContract{
          attributes{
            name
            value}
          currency {
          symbol
          name
          decimals
          tokenType
                  }
           }
          balance
              }
          transfers(date: {since: null, till: null}, amount: {gt: 0}) {
            minted: amount(calculate: sum
              sender: {is: "0x0000000000000000000000000000000000000000"}
              )
            burned: amount( calculate: sum 
              receiver : {is: "0x0000000000000000000000000000000000000000"}
              )
              currency(currency: {is: "${symbolInfo}"})
              {symbol
                name
                tokenId
              }
          } 
          dexTrades(
            baseCurrency: {is: "0x23ce9e926048273ef83be0a3a8ba9cb6d45cd978"}
            options: {desc: "trades"}
          ) {
            poolToken: smartContract {
              address {
                address
              }
            }
            exchange {
              fullName
            }
            pair: quoteCurrency {
              symbol
            }
            trades: count
          }
            }}`;
    
		
    const Lp = await fetch('https://graphql.bitquery.io', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'X-API-KEY': 'BQYDcYveR9Iec5NgkmSpl9HsVxeopmMQ',
      }),
      body: JSON.stringify({ query }),
      mode: 'cors',
    }).then((response) => {
      if (response.status >= 400) {
        return("Error fetching data");
      } else {
					 
        return response.json();
      }
    })
      .then((data) => {
       // console.log(data);
        return (data) || [];
      }).catch(function() {
        console.log("Empty Bitquery");
    });
    
      
    setisLoaded(true);
    if (Lp && Lp.data && Lp.data.ethereum && Lp.data.ethereum.address && Lp.data.ethereum.address[0] && Lp.data.ethereum.address[0].smartContract && Lp.data.ethereum.address[0].smartContract.currency && Lp.data.ethereum.address[0].smartContract.currency && Lp.data.ethereum.address[0].smartContract.currency.decimals ) {
      setisDecimals(Lp.data.ethereum.address[0].smartContract.currency.decimals)
      // console.log(Lp.data.ethereum.address[0].smartContract.currency.decimals)
    }
     if (Lp && Lp.data && Lp.data.ethereum && Lp.data.ethereum.address && Lp.data.ethereum.address[0] && Lp.data.ethereum.address[0].smartContract && Lp.data.ethereum.address[0].smartContract.attributes && Lp.data.ethereum.address[0].smartContract && Lp.data.ethereum.address[0].smartContract.attributes && Lp.data.ethereum.address[0].smartContract.attributes[0]) {
      {Lp.data.ethereum.address[0].smartContract.attributes.map((answer, i) => {                 
        if (answer.name === "totalSupply") {
          if (price !== 'NaN') {
        
       
            setisSupply(numberWithCommas((Number(answer.value / 1e18)).toFixed(0)))
          } else {
            setisSupply(0)
          }
        }
     })}
      
      
      
    }

     if (Lp && Lp.data && Lp.data.ethereum && Lp.data.ethereum.transfers && Lp.data.ethereum.transfers[0] && Lp.data.ethereum.transfers[0].minted && Lp.data.ethereum.transfers[0].minted) {
       if (price !== 'NaN') {
         const MC = (Lp.data.ethereum.transfers[0].minted - Lp.data.ethereum.transfers[0].burned) * Number(price);
        
         setIsMarketCap(numberWithCommas(Number((MC).toFixed(0))))
       } else {
        setIsMarketCap(0)
       }
    }

    
    
    window.clearTimeout(timer);
     timer = window.setTimeout(function () { setisLoaded(!isLoaded) }, 5000);
   } else {
     setIsMarketCap(0)
     setisSupply(0)
    window.clearTimeout(timer);
    timer = window.setTimeout(function () { setisLoaded(!isLoaded) }, 500);
    }
  }

  async function getWeeklyTokenPrice(Decimals) {
    let priceInUsd = 0;
    if (Decimals !== '0') {
      const functionResponse = await getAmountsOut(`${1 * Math.pow(10, Decimals)}`, [symbolInfo, '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', '0xe9e7cea3dedca5984780bafc599bd69add087d56'])
      
      if (functionResponse) {
        priceInUsd = Number(functionResponse.amounts[2].toString()) / Math.pow(10, 18)
        // console.log('weeklyToken', (priceInUsd))
      } else {
        // console.log('weeklyToken', (priceInUsd))
      
      }
    }
  
    if (priceInUsd) {
       if (priceInUsd > 1000) {
        setisPrice((priceInUsd).toFixed(2))
      } else if (priceInUsd > 100) {
        setisPrice((priceInUsd).toFixed(3))
      } else if (priceInUsd > 1) {
        setisPrice((priceInUsd).toFixed(4))
      } else if (priceInUsd > 0.1) {
        setisPrice((priceInUsd).toFixed(5))
      } else if (priceInUsd > 0.0001) {
        setisPrice((priceInUsd).toFixed(7))
      } else if (priceInUsd > 0.0000001) {
        setisPrice((priceInUsd).toFixed(9))
      } else if (priceInUsd > 0.000000001) {
        setisPrice( (priceInUsd).toFixed(11))
      } else if (priceInUsd > 0.00000000001) {
        setisPrice( (priceInUsd).toFixed(13))
      }else  {
        setisPrice( (priceInUsd).toFixed(17))
      }

      
    } else {
      setisPrice('NaN')
    }
    
    
     window.clearTimeout(timer2);
     timer2 = window.setTimeout(function(){ setisLoaded2(!isLoaded2) }, 1000);
    return priceInUsd
  }
 
  function update() {
    const NewsymbolInfo = symbolInfo
    if (NewsymbolInfo !== token) {
      setisSupply(numberWithCommas(0))
      setIsMarketCap(0)
    setistoken(NewsymbolInfo)
    window.clearTimeout(timer2);
     timer2 = window.setTimeout(function(){ setisLoaded2(!isLoaded2) }, 1000);
    }
      
  }
  const [isLoaded, setisLoaded] = useState(true);
  const [isLoaded2, setisLoaded2] = useState(true);
  const [Decimals, setisDecimals] = useState('0');
  const [supply, setisSupply] = useState(0);
  const [price, setisPrice] = useState('0');
  const [marketCap, setIsMarketCap] = useState(0);
  const [Data, setisData] = useState([]);

  const [token, setistoken] = useState(symbolInfo);

  useEffect(() => {

    getWeeklyTokenPrice(Decimals)

    }
    , [isLoaded2])
  
  
  
  useEffect(() => {
    loadBitqueryData()
    }
    , [isLoaded])
  
    
  
  
  useEffect(() => {
    update()
      }
    , [isLoaded2])
  

  return (
    <div className="flex p-4 mt-4 flex-wrap justify-between sm:flex-row">
      <div className="ml-4 p-4 sm:p-0 flex flex-col text-lg text-center"><p className="text-xl">Price</p> {price === 'NaN' ? <p className=" text-xl text-red-500">0</p> : <p className=" text-xl text-green-500">{price}</p> }</div> 
      
      <div className="ml-4 flex p-4 sm:p-0 flex-col text-lg text-center"><p className="text-xl">Supply</p> <p className=" text-xl text-green-500">{supply == 0 ? '...Loading' : supply}</p></div>

      <div className="mr-4 sm:mr-0 ml-4 sm:ml-0 flex p-4 sm:p-0 flex-col text-xl text-center"><p className="text-xl">Market Cap</p> <p className=" text-xl text-green-500">{marketCap == 0 ? '...Loading' : marketCap}</p></div>
    </div>
  )
  
})
 export default TVChartHeader
/* eslint-disable */

import React, { Component } from 'react';
import User from './User'
import Loading from './Loading'

const Scanner = React.memo(({
   currency,
   symbol,
   size = '24px',
   style
 }: {
     currency?: any
     symbol?: any
   size?: string
   style?: React.CSSProperties
 }) => { 
   
   const symbolInfo = currency || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
   
   type MyState = {
      user: string,
      value: string
      loading: boolean
      nameSubmit: string
      showHide: boolean
   };
   

   class MyScanner extends Component<{}, any> {
      constructor(props) {
         super(props);
         this.state = {
            user: '',
            loading: true,
            showHide: true,
         }
         
      }
   

      
      getUser = () => {
     

         // console.log(symbolInfo)
         const name = symbolInfo;
         setTimeout(() => {
            fetch(`https://app.staysafu.org/api/scan?tokenAddress=${name}&key=${process.env.REACT_APP_API_KEY}&holdersAnalysis=true`)
               .then(response => response.json())
               .then(data => {
                 console.log(data)
                  data = data.result
                  this.setState({
                     user: data,
                     nameSubmit: name,
                     loading: false,
                     showHide: false
                  });
               })
         }, 1000)
      }
 
      render() {
         let name;
     
       
         
         if (this.state.nameSubmit) {
            name = this.state.nameSubmit
         }
     
         if (this.state.nameSubmit) {
            name = this.state.nameSubmit
         }
         let userProfile;
         if (this.state.loading === true) {
            userProfile = <div className='loading-user-card'><Loading /></div>
         } else if (name) {
       
            userProfile = <User user={this.state.user} name={name} />
         }
         if (this.state.showHide === true) {
            {this.getUser()}
         }
         const meta = {
            title: 'Degem Scan',
            description: 'Degem Token Scanner',
            canonical: 'https://scan.degem.tech',
            meta: {
               charset: 'utf-8',
               name: {
                  keywords: 'react,meta,document,html,tags'
               }
            }
         };
         
         return (
     
         
             
         
            <div className=" py-14">
                    {userProfile}
            </div>
       
         );
      }
   }

   return <MyScanner />
})

 export default Scanner;
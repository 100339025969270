/* eslint-disable */

import axios from 'axios';
import Web3 from "web3";
import {dateRangeGlobal} from './date.js'
 

const supportedResolutions = ["1", "5", "10", "15", "30", "60", "120", "240", "D"]

const config = {
    supported_resolutions: supportedResolutions
}; 

function toChecksumAddress(address) {    
	if (Web3.utils.isAddress(address)) {
		return Web3.utils.toChecksumAddress(address);
	}
	return ''
};

function toTimestamp(strDate){
	var datum = Date.parse(strDate);
	return datum/1000;
}

export default class Poocoin {

	
	constructor(pOption) {

		this.debug = pOption.debug
		this.token = pOption.token
		this.symbol = pOption.symbol
		
        this.dateRange = dateRangeGlobal
        this.queryData = null
        this.labeltitle = ''
        this.state = {
            intervalId:null,
            data:null
      }
    
    };
    
	async loadBitqueryData(startTime, endTime, symbolInfo, resolution) {
		// console.log(resolution)
		if(resolution == undefined){
			resolution='60'
		}

		const interval = {
			'1': '1m',
			'3': '3m',
			'5': '5m',
			'15': '15m',
			'30': '30m',
			'60': '1h',
			'120': '2h',
			'240': '4h',
			'360': '6h',
			'480': '8h',
			'720': '12h',
			'D': '1d',
			'1D': '1d',
			'3D': '3d',
			'W': '1w',
			'1W': '1w',
			'M': '1M',
			'1M': '1M',
	  }[resolution]

      //  console.log(symbolInfo)
		 const toJSON = JSON.stringify;

		 let quoteCurrency = '0xe9e7cea3dedca5984780bafc599bd69add087d56'
		// console.log(this.symbol)
		 if (this.symbol === 'BUSD') {
			 quoteCurrency = '0x55d398326f99059ff775485246999027b3197955'
		 }

		 // console.log(symbolInfo)
		      let query = `
    {
       ethereum(network: bsc) {
			   dexTrades(baseCurrency: {is: "${symbolInfo}"}
				options: {desc:"trades"}) {
					poolToken: smartContract {
						 address { address }}
						 exchange { fullName }
						  pair: quoteCurrency {symbol }
						    trades: count } }}`;
    
		
		const Lp = await fetch('https://graphql.bitquery.io', {
			method: 'POST',
			headers: new Headers({
				 'Content-Type': 'application/json; charset=utf-8',
				 'X-API-KEY': 'BQYv86Wq2GmfQtjzlL6mWKiw4Tfdhw4O',
			}),
			body: JSON.stringify({ query }),
			mode: 'cors',
		 }).then((response) => {
				  if (response.status >= 400) {
					 throw new Error("Error fetching data");
				  } else {
					 
					 return response.json();
				  }
				})
			.then((data) => {
				 return (data && data.data && data.data.ethereum && data.data.ethereum.dexTrades &&data.data.ethereum.dexTrades[0].poolToken && data.data.ethereum.dexTrades[0].poolToken.address && data.data.ethereum.dexTrades[0].poolToken.address.address) || '';
				}).catch(function() {
                    console.log("error");
                });
		 
		 // console.log(Lp)
		 
		 const MyLp =  toChecksumAddress(Lp)
		// console.log(MyLp)
		 const res = await axios.get(`https://cors-dreams.herokuapp.com/https://api2.poocoin.app/candles-bsc?to=${new Date(endTime).toISOString()}&limit=250&lpAddress=${MyLp}&interval=${interval}&baseLp=0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16`, {
						headers: {
						  "Access-Control-Allow-Origin": "*",
						  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
						  "Access-Control-Allow-Headers": "Authorization"
						}
					}).then(res => {
						res = res.data
				//	console.log(res)
					
					return res
					}).catch(error => {
                        console.log("error");
                        return []
                    });
		 
		//   query = `
		//   {
		// 	ethereum(network: bsc) {
		// 	  dexTrades(
		// 		 options: {asc: "timeInterval.minute",limit: 20000}
		// 		 time: {since: ${toJSON(toISODateTime(startTime))}, till: ${toJSON(toISODateTime(endTime))}}
		// 		 exchangeAddress: {is: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"}
		// 		 baseCurrency: {is: "${symbolInfo}"},
		// 		 quoteCurrency: {is: "${quoteCurrency}"},
		// 	  ) 
		// 	  {
		// 		 timeInterval {
		// 			minute(count: 1, format: "%Y-%m-%dT%H:%M:%SZ")  
		// 		 }
		// 		 volume: quoteAmount
		// 		 high: quotePrice(calculate: maximum)
		// 		 low: quotePrice(calculate: minimum)
		// 		 open: minimum(of: block, get: quote_price)
		// 		 close: maximum(of: block, get: quote_price) 
		// 		 USD: tradeAmount(in: USD)
      //           Txs: count
      //           quotePrice
		// 	  }
		// 	}
		//  }`;
			
    
      //   const res = await fetch('https://graphql.bitquery.io', {
		// 	method: 'POST',
		// 	headers: new Headers({
		// 		 'Content-Type': 'application/json; charset=utf-8',
		// 		 'X-API-KEY': 'BQYv86Wq2GmfQtjzlL6mWKiw4Tfdhw4O',
		// 	}),
		// 	body: JSON.stringify({ query }),
		// 	mode: 'cors',
		//  });
        return await res;

        function toISODateTime(t) {
            return new Date(t).toISOString();
        }

        function toISODate(t) {
            return toISODateTime(t).split('T')[0];
        }
    }

    async loadBitqueryDataUSDT(startTime) {

        const toJSON = JSON.stringify;
    //    
	 const query = `
	 {
		 ethereum(network: bsc) {
			dexTrades(
			  options: {asc: "timeInterval.minute"}
			  time: {since: ${toJSON(toISODateTime(startTime))}, till: ${toJSON(toISODateTime(new Date()))}}
			  exchangeAddress: {is: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"}
			  baseCurrency: {is: "${symbolInfo.address}"},
			  quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"},
			  tradeAmountUsd: {gt: 10}
			) 
			{
				timeInterval {
				  minute(count: 15)
				}
				exchange {
				  name
				}
				transaction {
				  index
				}
				baseCurrency {
				  address
				  symbol
				}
				quoteCurrency {
				  address
				  symbol
				}
				USD: tradeAmount(in: USD, calculate: sum)
				Txs: count
				quotePrice
				maximum_price: quotePrice(calculate: maximum)
				minimum_price: quotePrice(calculate: minimum)
				open_price: minimum(of: block, get: quote_price)
				close_price: maximum(of: block, get: quote_price)
			 }
		 }
	  }`;
	  

	 const res = await fetch('https://graphql.bitquery.io', {
	  method: 'POST',
	  headers: new Headers({
			'Content-Type': 'application/json; charset=utf-8',
			'X-API-KEY': 'BQYv86Wq2GmfQtjzlL6mWKiw4Tfdhw4O',
	  }),
	  body: JSON.stringify({ query }),
	  mode: 'cors',
	});
        return await res.json();

        function toISODateTime(t) {
            return new Date(t).toISOString();
        }

        function toISODate(t) {
            return toISODateTime(t).split('T')[0];
        }
    }

   generateSymbol(exchange, fromSymbol, toSymbol) {
        const short = `${fromSymbol}/${toSymbol}`;
        return {
            short,
            full: `${exchange}:${short}`,
            currency_code: `${fromSymbol}`
        };
    }

	
    async onReady(callback) {

		 let allSymbols = [];
		// console.log(this.token)
         await this.loadBitqueryData(...this.dateRange,this.token,this.resolution).then(klines => {
				this.queryData = klines
				// console.log(klines)
            const symbols = klines.map(pd => {

                    const symbol = this.generateSymbol('Degem', this.symbol, 'BUSD');
                    return {
                        symbol: symbol.short,
                        full_name: symbol.full,
                        description: symbol.short,
                        exchange: 'Degem Exchange',
                        type: 'currency',
                        currency_code: symbol.currency_code
                    };
                
                
            });
            allSymbols = [...allSymbols, ...symbols];
            this.symbols = symbols
            callback({
					supports_marks: false,
					supports_timescale_marks: false,
					supports_time: true,
					supported_resolutions: [
						 '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720', '1D', '3D', '1W', '1M'
					]
			  })

        });
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
       // console.log(exchange,'exchange')
        userInput = userInput.toUpperCase()
        onResultReadyCallback(
            this.symbols.filter((symbol) => {
                return symbol.symbol.indexOf(userInput) >= 0
            }).map((symbol) => {
                return {
                    symbol: symbol.symbol,
                    full_name: symbol.symbol,
                    description: symbol.full_name,
                    ticker: symbol.symbol,
                    exchange: exchange,
                    type: 'crypto'
                }
            })
        )
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        this.debug && console.log('👉 resolveSymbol:', symbolName)

        const comps = symbolName.split(':')
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase()

        function pricescale(symbol) {
            if (typeof symbol.filters !== 'undefined') {
                for (let filter of symbol.filters) {
                    if (filter.filterType == 'PRICE_FILTER') {
                        return Math.round(1 / parseFloat(filter.tickSize))
                    }
                }
            }
            return 1
        }
		//  console.log(this.symbols)
		 for (let symbol of this.symbols) {
			var split_data = symbolName.split('/')
            if (symbol.symbol == symbolName) {
                setTimeout(() => {
                    onSymbolResolvedCallback({
							  name: symbol.symbol,
							  address: split_data[0],
                        description: symbol.full_name,
                        ticker: symbol.symbol,
                        exchange: 'Degem',
                        listed_exchange: 'Degem Exchange',
                        type: 'crypto',
                        session: '24x7',
                        minmov: 1,
                        pricescale: 1000000,
                        volume_precision: 1,
                        timezone: 'UTC',
                        has_intraday: true,
                        has_no_volume: false,
                        has_daily: false,
                        has_weekly_and_monthly: true,
                        intraday_multipliers: ['1', '10', '30', '60', '120', '240'],
                    })
                }, 0)
                return
            }
        }

        onResolveErrorCallback('not found')
    }

    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution)
            console.log('First:', firstDataRequest)
            console.log('From:', from, '(' + new Date(from * 1000).toGMTString() + ')')
            console.log('To:  ', to, '(' + new Date(to * 1000).toGMTString() + ')')
        }

        const interval = {
            '1': '1m',
            '3': '3m',
            '5': '5m',
            '15': '15m',
            '30': '30m',
            '60': '1h',
            '120': '2h',
            '240': '4h',
            '360': '6h',
            '480': '8h',
            '720': '12h',
            'D': '1d',
            '1D': '1d',
            '3D': '3d',
            'W': '1w',
            '1W': '1w',
            'M': '1M',
            '1M': '1M',
        }[resolution]

        if (!interval) {
            onErrorCallback('Invalid interval')
        }

        let totalKlines = []
        let totalBUKlines = []

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length)
            }

            if (totalKlines.length == 0) {
                onHistoryCallback([], { noData: true })
            } else {
                let busdArray = [];
                
                    
                totalKlines.map(tkl => {
                    
                        busdArray.push(tkl)
                    
                })
                busdArray.sort((a,b)=> new Date(a.time).getTime() - new Date(b.time).getTime())
                
					// console.log(busdArray)
					onHistoryCallback(busdArray.map((kl, index) => {
						// console.log(symbolInfo.address)
						if (symbolInfo.address == 'BUSD') {
							var time = toTimestamp(kl['time']) * 1000 //TradingView requires bar time in ms
							var closeValue = parseFloat(kl['close'] / kl['baseOpen'])
							var openValue = parseFloat(kl['open'] / kl['baseClose'])
							var highValue = parseFloat(kl['high'] / kl['baseLow'])
							var lowValue = parseFloat(kl['low'] / kl['baseHigh'])
							var volume = parseFloat(kl['volume'] * (kl['high'] * kl['baseHigh']))
						} else {
							var time = toTimestamp(kl['time']) * 1000 //TradingView requires bar time in ms
						 
							var closeValue = parseFloat(kl['close'] * kl['baseClose'])
							var openValue = parseFloat(kl['open'] * kl['baseOpen'])
							var highValue = parseFloat(kl['high'] * kl['baseHigh'])
							var lowValue = parseFloat(kl['low'] * kl['baseLow'])
							var volume = parseFloat(kl['volume'] * (kl['high'] * kl['baseHigh']))
							// console.log(busdArray[index].maximum_price,'/',kl.maximum_price,' date: ', kl.timeInterval.minute)
							// console.log(closeValue,'-C-',openValue,'-O-',highValue,'-H-',lowValue,'-L-')
						}
                    return {
                        time: time,
                        close: closeValue,
                        open: openValue,
                        high: highValue,
                        low: lowValue,
                        volume: volume
                    }
                    
                }), {
                    noData: false
                })
            }
        }


    const getKlines = (from, to, resolution) => {
          this.loadBitqueryData(from, to, this.token, resolution).then(klines => {
				 var bumpKlines = klines
				// console.log(bumpKlines)
            bumpKlines.map(bk=>{
                
                totalKlines = totalKlines.concat(bk)
            })
            

            if (bumpKlines.length == 1000) {
              from = new Date(bumpKlines[bumpKlines.length - 1].timeInterval.minute).getTime() + 1
              getKlines(from, to, resolution)
            } else {
              finishKlines()
            }
          }).catch(err => {
            console.error(err)
            onErrorCallback('Some problem')
          })

        
        }

        from *= 1000
        to *= 1000

        getKlines(from, to, resolution)
    }

    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        this.debug && console.log('👉 subscribeBars:', subscriberUID)
    }

    unsubscribeBars(subscriberUID) {
        this.debug && console.log('👉 unsubscribeBars:', subscriberUID)
    }

     getServerTime(callback) {
        const dateRange = dateRangeGlobal

        var serverTime = dateRange[1]

        var datetime = new Date(serverTime)
       // console.log(datetime)
        var dateTimeMili = datetime.getTime()
        setTimeout(() => callback(
            Math.floor(dateTimeMili / 1000)
        ));
    }
}

/* eslint-disable */
import { Currency, ETHER, Token } from '@pancakeswap-libs/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import './index.css';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	IChartingLibraryWidget,
	ResolutionString,
} from 'assets/js/charting_library';
import Poocoin from './api'
import EthereumLogo from '../../assets/images/binance-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from "../pancake/CoinLogo"



const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`


const ChartDisplay = React.memo(({
  currency,
  symbol,
  size = '24px',
  style
}: {
    currency?: any
    symbol?: any
  size?: string
  style?: React.CSSProperties
}) => { 
  

  interface ChartContainerProps {
    symbol: ChartingLibraryWidgetOptions['symbol'];
    interval: ChartingLibraryWidgetOptions['interval'];
  
    // BEWARE: no trailing slash is expected in feed URL
    datafeedUrl: string;
    libraryPath: ChartingLibraryWidgetOptions['library_path'];
    chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
    chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
    clientId: ChartingLibraryWidgetOptions['client_id'];
    userId: ChartingLibraryWidgetOptions['user_id'];
    fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
    autosize: ChartingLibraryWidgetOptions['autosize'];
    studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
    containerId: ChartingLibraryWidgetOptions['container_id'];
  }
  
   interface ChartContainerState {
  }
  
  function getLanguageFromURL(): LanguageCode | null {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
  }
  class TVChartContainer extends React.Component<ChartContainerProps, ChartContainerState> {  
	
    
      
    public static defaultProps: ChartContainerProps = {
      symbol: `${currency}-bsc` || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56-bsc' ,
      interval: '60' as ResolutionString,
      containerId: 'tv_chart_container',
      datafeedUrl: 'https://api.dex.guru/v1/tradingview',
      libraryPath: '/charting_library/',
      chartsStorageUrl: 'https://saveload.tradingview.com',
      chartsStorageApiVersion: '1.1',
      clientId: 'tradingview.com',
      userId: 'public_user_id',
      fullscreen: false,
      autosize: true,
      studiesOverrides: {},
    };
  
    private tvWidget: IChartingLibraryWidget | null = null;
  
    
  
    public componentDidMount(): void {
      const bqAPI = new Poocoin({ debug: false, token: currency || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', symbol: symbol || 'BUSD', symbols: { symbol: symbol || 'BUSD', full_name: symbol || 'BUSD', address: currency || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'} })
      
      const mySybmbole = symbol || 'BUSD';
      const myToken = currency|| '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
      
      const DData = `${mySybmbole}-${myToken}`
      // console.log(DData)
      const BData = `${mySybmbole}/BUSD`
      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: BData as string,
        // BEWARE: no trailing slash is expected in feed URL
        // tslint:disable-next-line:no-any
        datafeed: bqAPI as any,
        interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
        container_id: this.props.containerId as ChartingLibraryWidgetOptions['container_id'],
        library_path: this.props.libraryPath as string,
  
        locale: getLanguageFromURL() || 'en',
        disabled_features: ['use_localstorage_for_settings', "show_logo_on_all_charts", 
         "caption_buttons_text_if_possible", 
         "header_compare", "compare_symbol",  "header_saveload", 
        "header_interval_dialog_button", "show_interval_dialog_on_key_press", 
         "header_symbol_search"],
        enabled_features: ['study_templates'],
        charts_storage_url: this.props.chartsStorageUrl,
        charts_storage_api_version: this.props.chartsStorageApiVersion,
        client_id: this.props.clientId,
        user_id: this.props.userId,
        fullscreen: this.props.fullscreen,
        autosize: this.props.autosize,
        studies_overrides: this.props.studiesOverrides,
        theme: 'Dark',
      };
  
      const tvWidget = new widget(widgetOptions);
      this.tvWidget = tvWidget;
  
      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const mySybmbole = currency?.symbol || 'BTCUSDT';
          const button = tvWidget.createButton();
          button.setAttribute('title', 'Click to show a notification popup');
          button.classList.add('apply-common-tooltip');
          button.addEventListener('click', () => tvWidget.showNoticeDialog({
              title: 'Notification',
              body: 'TradingView Charting Library API works correctly',
              callback: () => {
                console.log('Noticed!');
              },
            }));
          button.innerHTML = 'Check API';
        });
      });
    }
  
  
  
    
    public render(): JSX.Element {
      return (
        <div
          id={ this.props.containerId }
          className='TVChartContainer'
        />
      );
    }
  }


  
  return <TVChartContainer />
  
})
 export default ChartDisplay
/* eslint-disable */
import { Currency, ETHER, Token } from '@pancakeswap-libs/sdk'
import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import Loading from 'components/Scanner/Loading'
import './index.css';

import Poocoin from './api'
import EthereumLogo from '../../assets/images/binance-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Logo from '../Logo'
import CoinLogo from "../pancake/CoinLogo"
import { ethers } from 'ethers'
import pcsRouter from './web3/pcsRouter.js'
import numberWithCommas from './web3/numberWithCommas'
import { Exchange } from '../../../build/js/charting_library/charting_library';

let timer
let timer2


const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`
const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.defibit.io/")





const TVTxns = React.memo(({
  currency,
  symbol,
  size = '24px',
  style
}: {
    currency?: any
    symbol?: any
  size?: string
  style?: React.CSSProperties
}) => { 
  

  const NewsCard = (props) => {
    return (
      <Tr className="rt-tr-group">
        <Td className="p-4">
                <span className={`bg-${props.color}-400 text-gray-50 rounded-md ml-2 py-3 px-4`}>{props.type}</span>
             </Td>
            <Td className="p-3">
          
          <div className="ml-3">
                    <div className="">{props.amount}</div>
                    <div className="text-gray-500">${props.symbol}</div>
                  </div>
             </Td>
            <Td className="p-3 font-bold">
                 <div className="ml-3">
                    <div className="">{props.amountUSD} USD</div>
            <div className="text-gray-500">{props.amountBNB} {props.quotesymbol }</div>
                  </div>
            
             </Td>
            <Td className="p-3 ">
            {props.time}
           </Td>
           
           <Td className="p-3 ">
                    <a href={`https://bscscan.com/tx/${props.myurl}`} target="_blank" >
                    {`${props.myurl? props.myurl.substring(0, 15) : ''}...`}
                    </a>
                    </Td>
              <Td className="p-3 text-white ">
              {props.Exchange}
              </Td>        
        </Tr>
    );
  };

  const symbolInfo = currency || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';

  async function loadBitqueryData(currency) {

    const minutesToAdd=60;
    const currentDate = new Date();
    const time = new Date(currentDate.getTime() + minutesToAdd*60000).toISOString();
   
    const symbolInfo = currency || '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
    let query = `
    {
      ethereum(network: bsc) {
        dexTrades(
          options: {desc: ["block.height", "tradeIndex"], limit: 10, offset: 0}
          date: {since: "${time}"}
          baseCurrency: {is: "${symbolInfo}"}
        ) {
          block {
            timestamp {
              time(format: "%Y-%m-%d %H:%M:%S")
            }
            height
          }
          tradeIndex
          exchange {
            fullName
          }
          smartContract {
            address {
              address
              annotation
            }
          }
          baseAmount
          baseCurrency {
            address
            symbol
          }
          buyCurrency {
            symbol
            address
          }
          sellCurrency {
            symbol
            address
          }
          quoteAmount
          quoteCurrency {
            address
            symbol
          }
          transaction {
            hash
          }
          tradeAmount(in: USD)
        }
      }
    }`;
    
		
    const Lp = await fetch('https://graphql.bitquery.io', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'X-API-KEY': 'BQYvhnv04csZHaprIBZNwtpRiDIwEIW9',
      }),
      body: JSON.stringify({ query }),
      mode: 'cors',
    }).then((response) => {
      if (response.status >= 400) {
        return("Error fetching data");
      } else {
					 
        return response.json();
      }
    })
      .then((data) => {
        //console.log(data);
        return data
      })
      .then(res => {
        res = res.data
        // console.log(res)
    
        return res || []
      })
      .then(body => {
        if (body && body.ethereum && body.ethereum.dexTrades && body.ethereum.dexTrades[0]) {
          return body.ethereum.dexTrades.map(trade => {
            return {
              type: trade.exchange.fullName,
              amount: trade.baseAmount,
              amountUSD: trade.tradeAmount,
              amountBNB: trade.quoteAmount,
              quotesymbol: trade.quoteCurrency.symbol,
              symbol: trade.baseCurrency.symbol,
              time: trade.block.timestamp.time,
              transaction: trade.transaction.hash,
              sellCurrency: trade.sellCurrency.address,
              sellsymbol: trade.sellCurrency.symbol,
              Exchange: trade.exchange.fullName,
              color: trade.sellCurrency.address === symbolInfo ? 'red' : 'green'
            }
          })
          
        } 
        
        
      }).then(mybody => {
        //console.log((result))
        setHits([...mybody]);
        //console.log(body.ethereum.dexTrades)
      
       })
      .catch(error => console.error('Error', error));

    
    
    window.clearTimeout(timer);
     timer = window.setTimeout(function () { setisLoaded(true) }, 10000);
   
  }

 
 
  function update(currency,token) {


    
    if (currency !== token) {
      setisSupply(numberWithCommas(0))
      setIsMarketCap(0)
      setistoken(currency)
    window.clearTimeout(timer2);
     timer2 = window.setTimeout(function(){ loadBitqueryData(currency), setisLoaded2(!isLoaded2),setisLoaded(false) }, 500);
    } else {
      window.clearTimeout(timer2);
      timer2 = window.setTimeout(function(){  setisLoaded2(!isLoaded2),setisLoaded(true) }, 3500);
    }
      
  }
  const [isLoaded, setisLoaded] = useState(false);
  const [isLoaded2, setisLoaded2] = useState(true);
  const [hits, setHits] = useState<
    Array<{
      type: string
      amount: string
      amountUSD: string
      amountBNB: string
      symbol: string
      time: any
      transaction: string
      color: string
      sellCurrency: string
      sellsymbol: string
      Exchange: string
      quotesymbol: string
    }>
    >([]);
  
  //console.log(hits)
  const [supply, setisSupply] = useState(0);
  const [price, setisPrice] = useState('0');
  const [marketCap, setIsMarketCap] = useState(0);
  const [Data, setisData] = useState([]);

  const [token, setistoken] = useState(symbolInfo);

  
  
  
    
  useEffect(() => {
    loadBitqueryData(currency)
      }
    , [isLoaded])
  
  
  useEffect(() => {
    update(currency,token)
      }
    , [isLoaded2])
  

  return (


    <div className="p-4">
          <div className="lg:pl-4 lg:pr-4 pb-10 md:pl-0 md:pr-0 ">
       
        <div className="overflow-auto lg:overflow-visible ">
                  
                    
          



          {isLoaded && hits[0] ? (
            <Table className="table text-gray-50 border-separate space-y-6 text-sm rounded-lg">
            <Thead className="bg-gray-800 text-gray-100 rounded-lg">
                <Tr>
                  <Th className="p-3">Type</Th>
                  <Th className="p-3 text-left">Tokens</Th>
                  <Th className="p-3 text-left">Price</Th>
                  <Th className="p-3 text-left">Time</Th>
                 
                <Th className="p-3 text-left ">Tx</Th>
                <Th className="p-3 text-left ">Exchange</Th>
                </Tr>
              </Thead>
              <Tbody>
        
                {hits.map((item, index) => {
                  // console.log(item)
                  if (item && item.sellsymbol === item.symbol) {
                    return (
                      <NewsCard
                        color="green"
                        type="buy"
                        key={index}
                        symbol={item.symbol}
                        myurl={item.transaction}
                        mytype={item.type}
                        sellCurrency={item.sellCurrency}
                        sellsymbol={item.sellsymbol}
                        quotesymbol={item.quotesymbol}
                        Exchange={item.Exchange}
                        time={((new Date(`${item.time} GMT`).toString()).toLocaleString()).split('G')[0] }
                        amountUSD={`${numberWithCommas(Number(item.amountUSD).toFixed(2))}`}
                        amountBNB={`${numberWithCommas(Number(item.amountBNB).toFixed(2))}`}
                        amount={`${numberWithCommas(Number(item.amount).toFixed(2))}`}
                      />
                    );
                  } else if (item && item.sellsymbol !== item.symbol) {
                    return (
                      <NewsCard
                        color="red"
                        type="sell"
                        key={index}
                        symbol={item.symbol}
                        myurl={item.sellCurrency}
                        time={((new Date(`${item.time} GMT`).toString()).toLocaleString()).split('G')[0]}
                        mytype={item.type}
                        sellCurrency={item.sellCurrency}
                        sellsymbol={item.sellsymbol}
                        quotesymbol={item.quotesymbol}
                        Exchange={item.Exchange}
                        amountUSD={`${numberWithCommas(Number(item.amountUSD).toFixed(2))}`}
                        amountBNB={`${numberWithCommas(Number(item.amountBNB).toFixed(2))}`}
                        amount={`${numberWithCommas(Number(item.amount).toFixed(2))}`}
                      />
                    );
                  }
                  else {
                    return (
                      '')
                  }
                    
                })}
              </Tbody>
              </Table>
			) : (
				<div className='loading-user-card mx-auto'><Loading /></div>
                )} 
                
    
                  
            </div></div>
          </div>
        
  )
  
})
 export default TVTxns
/* eslint-disable */

function generalDateRange() {
	var range=[]
	var today = new Date();
	var thisyear = today.getFullYear();
	var lastyear = thisyear
	var beforeDay = parseInt(today.getDate()) - 3;
	var thisMonth = today.getMonth()<10?'0'+(today.getMonth() + 1):(today.getMonth() + 1)
	var lastMonth = thisMonth
	if(beforeDay<=0)
	 lastMonth = today.getMonth()<10?'0'+(today.getMonth()):(today.getMonth())
	if(thisMonth == '01'){
	  lastMonth = '12'
	  lastyear = thisyear - 1
	}
	
	var thisDay = today.getDate()<10?'0'+(today.getDate()):today.getDate();
	var lastDay = (beforeDay<10)?'0'+beforeDay:beforeDay
	if(beforeDay<=0)lastDay = 30 + beforeDay
	var thisMonthToday = thisyear+'-'+thisMonth+'-'+thisDay
	var lastMonthToday = lastyear+'-'+lastMonth+'-'+lastDay
	var Hours = today.getHours()<10?'0'+today.getHours():today.getHours()
	var Minutes = today.getMinutes()<10?'0'+today.getMinutes():today.getMinutes()
	var Seconds = today.getSeconds()<10?'0'+today.getSeconds():today.getSeconds();
	var time = Hours+ ":" + Minutes + ":" + Seconds
	var fromDateTime = lastMonthToday + 'T' + time + 'Z'
	var toDateTime = thisMonthToday + 'T' + time + 'Z'
	range.push(fromDateTime)
	range.push(toDateTime)
	return range
}
export const dateRangeGlobal = generalDateRange()